import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
import * as whintegration from "@mod-system/js/wh/integration";

import "@mod-mso_templates/webdesigns/penitus/penitus";

import "./widgets/textimage/textimage";

import "./camping_meuleman.scss";

dompack.onDomReady(() => {
  new mso.MSOSite();

  if (whintegration.config.obj.activelang == "de") {
    let extrafooter = document.createElement("div");

    extrafooter.innerHTML = `<div class="footer__extra">
        <div><img src="${whintegration.config.obj.imgroot}interreg.jpeg"/></div>
        <div>Dieses Projekt wird im Rahmen des INTERREG-Programms von der Eureiopäischen Union und den INTERREG-Partnern finanziell unterstützt.</div>
      </div>`;
    dompack.qS("footer").append(extrafooter);
  }
});
